:root {
  --base-size: 16px;

  /* Fonts */
  --std-font-xxs: 10px;
  --std-font-xs: 12px;
  --std-font-sm: 14px;
  --std-font-md: 16px;
  --std-font-lg: 18px;
  --std-font-xlg: 20px;
  --std-font-2xlg: 24px;
  --std-font-3xlg: 36px;
  --std-font-4xlg: 48px;
  --std-font-5xlg: 72px;

  /* Margins */
  --std-margin-xxs: 4px;
  --std-margin-xs: 8px;
  --std-margin-sm: 12px;
  --std-margin-md: 16px;
  --std-margin-lg: 20px;
  --std-margin-xlg: 24px;
  --std-margin-2xl: 32px;
  --std-margin-3xl: 48px;
  --std-margin-4xl: 64px;
  --std-margin-5xl: 96px;

  /* Paddings */
  --std-padding-xxs: 4px;
  --std-padding-xs: 8px;
  --std-padding-sm: 12px;
  --std-padding-md: 16px;
  --std-padding-lg: 20px;
  --std-padding-xlg: 24px;
  --std-padding-2xl: 32px;
  --std-padding-3xl: 48px;
  --std-padding-4xl: 64px;
  --std-padding-5xl: 96px;

  /* Border radius */
  --std-border-radius: 5px;
  --std-border: 2px solid var(--grey600);
  --std-border-hover: 2px solid var(--darkgrey);
  --std-border-tooltip: 1px solid var(--grey600);

  /* Renaissance brand colours */
  --beige: #f1efe3;
  --red: #cf3a4e;
  --blue: #146eb3;
  --lightblue: #9fb7d1;
  --linkblue: #3771c7; /* defined for the Leader Journey links */
  --white: #ffffff;
  --lightgrey: #f3f5f7;
  --darkgrey: #54585e;
  --inputhovergrey: #b3b3b3;
  --tablegrey: #202020; /* table text color and leader journey */
  --tablebodygrey: #f8f8f8;
  --tabledividergrey: #dddee0; /* table row divider color */
  --chartgrey: #979797; /* chart color */
  --engagementgrey: #808285; /* leader journey - engagement tile color */
  --black: #000000;
  --transparent-blue: hsl(206, 80%, 39%, 0.5);
  --more-transparent-blue: hsl(206, 80%, 39%, 0.2);

  /* New Greys */
  --grey600: #707070;
  --grey700: #555555;
}
